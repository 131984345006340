import type {
  ControllerManifest,
  WidgetManifest,
} from '@wix/platform-editor-sdk';

export const blocksPostPageManifest: ControllerManifest | WidgetManifest = {
  default: {
    behavior: {
      removable: false,
      duplicatable: false,
      toggleShowOnAllPagesEnabled: false,
      essential: {
        enabled: false,
      },
    },
    gfpp: {
      desktop: {
        widgetDesign: { title: 'Post Page Design', tabs: [] },
        widgetPresets: {
          presets: [
            {
              id: 'variants-ln2sz6bn1',
              src: '',
            },
          ],
        },
        iconButtons: {},
      },
    },
  },
};
